import React, { useState } from 'react';

import { theme } from '~/theme';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import useAccommodationPropertyDetailsContext from '~/components/Accommodation/Pages/PropertyDetails/context/useAccommodationPropertyDetailsContext';
import AccommodationPropertyRatesResultsTable from '~/components/Accommodation/Pages/PropertyDetails/sections/AccommodationPropertyRates/AccommodationPropertyRatesResultsTable';

import { allRegions } from '~/consts/allRegions';
import { brands } from '~/consts/brands';

import { Rate, RoomRates, getAvailability } from '~/services/AccommodationService';
import { Dayjs } from '~/services/TimeService';

import BestAvailableRate from './BestAvailableRate';

export default function AccommodationPropertyRatesSection() {
  const { propertyDetails } = useAccommodationPropertyDetailsContext();

  const [checkIn, setCheckIn] = useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = useState<Dayjs | null>(null);
  const [allRates, setAllRates] = useState(false);
  const [region, setRegion] = useState<App.MembershipRegion>('AU');
  const [brand, setBrand] = useState<App.Brands>('luxuryescapes');
  const [occupancy, setOccupancy] = useState({
    adults: 2,
    children: 0,
    infants: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [roomRates, setRoomRates] = useState<RoomRates>();

  const [lowestPricedRoomRate, setLowestPricedRoomRate] = useState<{ rate: Rate; roomName: string } | null>(null);
  const [noRatesFound, setNoRatesFound] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState('');

  const handleSearch = async () => {
    setIsLoading(true);
    const response = await getAvailability({
      checkIn: checkIn?.format('YYYY-MM-DD') ?? '',
      checkOut: checkOut?.format('YYYY-MM-DD') ?? '',
      occupancies: [occupancy.adults.toString()],
      propertyIds: [propertyDetails.id],
      region,
      brand,
      allRates,
    });

    if (
      Object.entries(response.result.properties).length === 0 ||
      Object.entries(response.result.properties[propertyDetails.id].rooms).length === 0
    ) {
      setNoRatesFound(true);
      setIsLoading(false);
      return;
    }

    setRoomRates(response.result.properties[propertyDetails.id].rooms);
    setLowestPricedRoomRate(findLowestPricedRoomRate(response.result.properties[propertyDetails.id].rooms));
    setIsLoading(false);
  };

  const findLowestPricedRoomRate = (rooms: RoomRates): { rate: Rate; roomName: string } | null => {
    let lowestRate: Rate | null = null;

    for (const [, room] of Object.entries(rooms)) {
      if (!room.rates?.length) {
        continue;
      }

      for (const rate of room.rates) {
        if (!rate.total?.sellingPrice) {
          continue;
        }

        if (!lowestRate || rate.total.sellingPrice < lowestRate.total.sellingPrice) {
          lowestRate = rate;
        }
      }
    }

    return { rate: lowestRate, roomName: rooms[lowestRate.roomId].name };
  };

  const openModal = (title: string, content: React.ReactNode) => {
    setModalTitle(title);
    setModalContent(content);
    setModalOpen(true);
  };

  return (
    <>
      <Stack spacing={4}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} flexWrap="wrap" useFlexGap>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack flex={1} minWidth={240}>
              <DatePicker
                label="Check-in Date"
                format="DD/MM/YYYY"
                value={checkIn}
                onChange={(newValue) => setCheckIn(newValue)}
                slotProps={{ textField: { fullWidth: true, variant: 'outlined' } }}
                disablePast
              />
            </Stack>

            <Stack flex={1} minWidth={240}>
              <DatePicker
                label="Check-out Date"
                format="DD/MM/YYYY"
                value={checkOut}
                onChange={(newValue) => setCheckOut(newValue)}
                slotProps={{ textField: { fullWidth: true, variant: 'outlined' } }}
                disablePast
                minDate={checkIn ? checkIn.add(1, 'day') : undefined}
              />
            </Stack>
          </LocalizationProvider>

          <Stack direction="row" spacing={1} flex={1} minWidth={240}>
            <TextField
              label="Adults"
              type="number"
              value={occupancy.adults}
              onChange={(e) => setOccupancy({ ...occupancy, adults: parseInt(e.target.value) })}
              inputProps={{ min: 1 }}
              size="small"
              fullWidth
            />
            <TextField
              label="Children"
              type="number"
              value={occupancy.children}
              onChange={(e) => setOccupancy({ ...occupancy, children: parseInt(e.target.value) })}
              inputProps={{ min: 0 }}
              size="small"
              fullWidth
            />
            <TextField
              label="Infants"
              type="number"
              value={occupancy.infants}
              onChange={(e) => setOccupancy({ ...occupancy, infants: parseInt(e.target.value) })}
              inputProps={{ min: 0 }}
              size="small"
              fullWidth
            />
          </Stack>

          <Stack flex={1} maxWidth={80}>
            <Select
              fullWidth
              value={region}
              onChange={(e) => setRegion(e.target.value as App.MembershipRegion)}
              label="Region"
            >
              {allRegions.map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Stack flex={1} minWidth={160}>
            <Select fullWidth value={brand} onChange={(e) => setBrand(e.target.value as App.Brands)} label="Brand">
              {brands.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  {brand.title}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Stack minWidth={120}>
            <FormControlLabel
              control={<Checkbox checked={allRates} onChange={(e) => setAllRates(e.target.checked)} />}
              label="All Rates"
            />
          </Stack>

          <Stack minWidth={120}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
              disabled={!checkIn || !checkOut || isLoading}
            >
              Search
            </Button>
          </Stack>
        </Stack>
        {lowestPricedRoomRate && (
          <BestAvailableRate
            rate={lowestPricedRoomRate.rate}
            roomName={lowestPricedRoomRate.roomName}
            openModal={openModal}
          />
        )}
        {roomRates && <AccommodationPropertyRatesResultsTable roomRates={roomRates} openModal={openModal} />}
        {noRatesFound && (
          <Box sx={{ p: 2, bgcolor: '#ffebee', borderRadius: 1 }} textAlign="center">
            <Typography>No rates found for the selected dates</Typography>
          </Box>
        )}
      </Stack>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {modalTitle}
          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{modalContent}</DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
