import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import FlightIcon from '@mui/icons-material/Flight';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import { Rate } from '~/services/AccommodationService';

import MetaInfoItem from './MetaInfoItem';

interface RateDetailsModalProps {
  rate: Rate;
}

export default function RateDetailsModal({ rate }: RateDetailsModalProps) {
  const theme = useTheme();
  const hasPayableAtProperty = rate.propertyFees && rate.propertyFees.length > 0;

  const isPackaged = (meta: Rate['meta']) => {
    if (!meta) {
      return false;
    }
    return 'packaged' in meta ? meta.packaged : false;
  };

  const formatPrice = (price: number | undefined) => {
    if (price === undefined || price === null) {
      return 'N/A';
    }
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  };

  const formatMetaKey = (key: string): string => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
        Rate Details:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">Pricing Information</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Selling Price:
                </Typography>{' '}
                {formatPrice(rate.total.sellingPrice)}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Display Price:
                </Typography>{' '}
                {formatPrice(rate.total.displayPrice)}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Before Taxes/Fees:
                </Typography>{' '}
                {formatPrice(rate.total.beforeTaxesFeesPrice)}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Taxes and Fees:
                </Typography>{' '}
                {formatPrice(rate.total.taxesAndFees)}
              </Typography>
              {hasPayableAtProperty && (
                <Typography sx={{ color: theme.palette.warning.dark, fontWeight: 'bold', mt: 1 }}>
                  <PaymentsIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  Pay at Property: {formatPrice(rate.total.propertyFees)}
                </Typography>
              )}
              <Box sx={{ mt: 1, p: 1, bgcolor: theme.palette.grey[100], borderRadius: 1 }}>
                <Typography variant="subtitle2">Total Cost:</Typography>
                <Typography variant="h6" color="primary">
                  {formatPrice(rate.total.displayPrice)}
                </Typography>
                {rate.total.discount > 0 && (
                  <Typography color="secondary" sx={{ fontWeight: 'bold', mt: 1 }}>
                    <LoyaltyIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Discount: {(rate.total.discount * 100).toFixed(0)}% off
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 0 }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Rate Information</Typography>
              <Divider sx={{ my: 1 }} />
              {rate.name && (
                <Typography>
                  <Typography component="span" fontWeight="bold">
                    Rate Name:
                  </Typography>{' '}
                  {rate.name}
                </Typography>
              )}
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Rate ID:
                </Typography>{' '}
                {rate.id}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Board Code:
                </Typography>{' '}
                {rate.boardCode}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Supplier:
                </Typography>{' '}
                {rate.meta && 'supplier' in rate.meta ? rate.meta.supplier : 'N/A'}
              </Typography>
              <Typography>
                <Typography component="span" fontWeight="bold">
                  Packaged:
                </Typography>{' '}
                {isPackaged(rate.meta) ? 'Yes' : 'No'}
              </Typography>
              {isPackaged(rate.meta) && (
                <Box sx={{ mt: 1, p: 1, bgcolor: theme.palette.info.light, borderRadius: 1 }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <FlightIcon sx={{ mr: 1 }} />
                    This rate includes a flight bundle
                  </Typography>
                </Box>
              )}
              {rate.meta && (
                <Box sx={{ mt: 2 }}>
                  <Chip
                    label={JSON.stringify(rate.meta).toLowerCase().includes('bedbank') ? 'Bedbank' : 'LPC'}
                    color="primary"
                    size="small"
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Card sx={{ marginLeft: 2, marginTop: 0 }}>
          {rate.meta && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">Additional meta data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(rate.meta)
                  .filter(([key]) => !['group', 'facilities', 'promotions', 'bedGroups'].includes(key))
                  .map(([key, value]) => {
                    if (typeof value === 'string' || typeof value === 'number') {
                      return <MetaInfoItem key={key} label={formatMetaKey(key)} value={value.toString()} />;
                    }
                    return null;
                  })}
              </AccordionDetails>
            </Accordion>
          )}
        </Card>
      </Grid>
    </Box>
  );
}
