export { SUPPLIER_NAMES, SuppliersCodes } from '@luxuryescapes/contract-svc-bedbank/constants/suppliers';

export const BEDBANK_PROPERTY_TYPE_HOTEL = 'HOTEL';
export const BEDBANK_PROPERTY_TYPE_UNIQUE_STAYS = 'UNIQUE_STAYS';

export const BEDBANK_PROPERTY_TYPES_ENUM = [BEDBANK_PROPERTY_TYPE_HOTEL, BEDBANK_PROPERTY_TYPE_UNIQUE_STAYS];

export const BEDBANK_PROPERTY_STATUS_DRAFT = 'draft';
export const BEDBANK_PROPERTY_STATUS_CONTENT_APPROVED = 'content-approved';
export const BEDBANK_PROPERTY_STATUS_NOT_CURATED = 'not-curated';
export const BEDBANK_PROPERTY_STATUS_AD_FEED_ONLY = 'ad-feed-only';
export const BEDBANK_PROPERTY_STATUS_SINGLE_PROPERTY = 'single-property';

export const BEDBANK_PROPERTY_STATUSES_ENUM = [
  BEDBANK_PROPERTY_STATUS_DRAFT,
  BEDBANK_PROPERTY_STATUS_CONTENT_APPROVED,
  BEDBANK_PROPERTY_STATUS_NOT_CURATED,
  BEDBANK_PROPERTY_STATUS_AD_FEED_ONLY,
  BEDBANK_PROPERTY_STATUS_SINGLE_PROPERTY,
];

export const BEDBANK_PROPERTY_STATUS_LPP = 'LPP';
export const BEDBANK_PROPERTY_STATUS_LPC = 'LPC';

export const BEDBANK_PROPERTY_STYLES_ENUM = [BEDBANK_PROPERTY_STATUS_LPP, BEDBANK_PROPERTY_STATUS_LPC];

export const BEDBANK_PROPERTY_DOMAIN_LE = 'le';
export const BEDBANK_PROPERTY_DOMAIN_LED = 'led';
export const BEDBANK_PROPERTY_DOMAIN_ALL = 'all';
export const BEDBANK_PROPERTY_DOMAIN_NONE = 'none';
export const BEDBANK_PROPERTY_DOMAIN_NONE_LE_OFFER = 'none-le-offer';

export const BEDBANK_PROPERTY_DOMAINS_ENUM = [
  BEDBANK_PROPERTY_DOMAIN_LE,
  BEDBANK_PROPERTY_DOMAIN_LED,
  BEDBANK_PROPERTY_DOMAIN_ALL,
  BEDBANK_PROPERTY_DOMAIN_NONE,
  BEDBANK_PROPERTY_DOMAIN_NONE_LE_OFFER,
];

export const BEDBANK_PROFILE_DEFAULT = 'default';
export const BEDBANK_PROFILE_MARRIOTT = 'marriott';

export const BEDBANK_PROFILES_ENUM = [BEDBANK_PROFILE_DEFAULT, BEDBANK_PROFILE_MARRIOTT];

export const BEDBANK_COMMENT_POST_ACCOM = 'pre/post-accom';
export const BEDBANK_COMMENT_NOT_GOOD_QUALITY = 'not good enough quality';
export const BEDBANK_LIVE_MARKETPLACE_OFFER = 'live marketplace offer';
export const BEDBANK_LOW_MARGIN = 'low margin';
export const BEDBANK_COMMERCIAL_REQUEST = 'commercial request';
export const BEDBANK_QUALITY_CONCERNS = 'quality concerns';
export const BEDBANK_LOW_SALES_PERFORMANCE = 'low sales performance';
export const BEDBANK_HIGH_VIEWS_NO_SALES = 'high views/no sales';

export const BEDBANK_COMMENTS_ENUM = [
  BEDBANK_COMMENT_POST_ACCOM,
  BEDBANK_COMMENT_NOT_GOOD_QUALITY,
  BEDBANK_LIVE_MARKETPLACE_OFFER,
  BEDBANK_LOW_MARGIN,
  BEDBANK_COMMERCIAL_REQUEST,
  BEDBANK_QUALITY_CONCERNS,
  BEDBANK_LOW_SALES_PERFORMANCE,
  BEDBANK_HIGH_VIEWS_NO_SALES,
];

export const BEDBANK_SUPPLIER_INFO: Record<string, { email?: string; phone?: string; portal?: string }> = {
  expedia: {
    email: 'a2a@chat.expediapartnersolutions.com',
    phone: '1800 812 594',
    portal: 'https://voyager.expedia.com/',
  },
  hotelbeds: {
    email: 'seapac.english@hotelbeds.com',
    phone: '+66 2026 8386',
  },
  webbeds: {
    email: 'aus-customerservice@webbeds.com',
    phone: '+61 1800767593',
  },
  dida: {
    email: 'servicejp@dida.com',
    phone: '+86-755-82639330',
  },
  nuitee: {
    phone: '+1 844 727 0478',
  },
  bonotel: {
    email: 'bookings@bonotel.com',
  },
  mgbedbank: {},
  miki: {
    email: 'fit-bkk@group-miki.com',
  },
};
