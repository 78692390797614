import React, { useMemo } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { PaymentSchedulePaymentMethodTypes } from '~/consts/payment';

import { PaymentScheduleDetail, PaymentScheduleDetailsResponse } from '~/services/PaymentsService';
import { formatDateSlashes } from '~/services/TimeService';

import { Payment } from '~/types/services/payment';

import currencyFormatter from '~/utils/currencyFormatter';
import isProd from '~/utils/isProd';

interface Props {
  paymentScheduleDetails: PaymentScheduleDetailsResponse;
  payments: Array<Payment>;
}

function paymentScheduleMapper(paymentSchedule: PaymentScheduleDetail) {
  return {
    id: paymentSchedule.id,
    transactionKey: paymentSchedule.transaction_key,
    status: paymentSchedule.status,
    type: paymentSchedule.payment_type,
    amount: paymentSchedule.total_amount,
    dueDate: paymentSchedule.due_date,
    retryAttempts: paymentSchedule.retry.job_retry_attempts,
    failureReason: paymentSchedule.failure_reason,
    paidDate: paymentSchedule.paid_date,
    nextChargeDate: paymentSchedule.next_charge_date,
    initialAmount: paymentSchedule.initial_total_amount,
    feeAmount: paymentSchedule.fee_amount,
    baseAmount: paymentSchedule.base_amount,
    items: paymentSchedule.items,
  };
}

function formatPaymentScheduleStatus(paymentScheduleStatus: string) {
  switch (paymentScheduleStatus) {
    case 'active': {
      return 'In Progress';
    }
    default: {
      return paymentScheduleStatus;
    }
  }
}

function mapTransactionKeyToTransactionId(payments: Array<Payment>) {
  const paymentTransactionKeys = {};

  payments.map((payment) => {
    paymentTransactionKeys[payment.transaction_key] = payment.id_payment;
  });

  return paymentTransactionKeys;
}

function getStripeSubscriptionLink(subscriptionId: string) {
  const isTest = isProd() ? '' : '/test';
  return `https://dashboard.stripe.com${isTest}/subscription_schedules/${subscriptionId}`;
}

export default function OrderPaymentScheduleDetails({ paymentScheduleDetails, payments }: Props) {
  const paymentsMap = useMemo(() => {
    return mapTransactionKeyToTransactionId(payments);
  }, [payments]);

  const currencyCode = useMemo(() => {
    return paymentScheduleDetails?.result?.currency;
  }, [paymentScheduleDetails?.result?.currency]);

  const paymentScheduleRows = useMemo(() => {
    return paymentScheduleDetails?.result?.payment_schedules?.map(paymentScheduleMapper);
  }, [paymentScheduleDetails?.result?.payment_schedules]);

  const stripeSubscriptionLink = getStripeSubscriptionLink(paymentScheduleDetails.result.payment_method_id);

  const columns: Array<GridColDef> = [
    {
      field: 'transactionKey',
      headerName: 'Transaction ID',
      display: 'flex',
      valueGetter: (value) => {
        return paymentsMap[value] ?? '';
      },
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'type',
      headerName: 'Payment Type',
      flex: 1.1,
      display: 'flex',
    },
    {
      field: 'initialAmount',
      valueFormatter: (value) => {
        return currencyFormatter(currencyCode, value, 2);
      },
      headerName: 'Initial Amount',
      flex: 1,
    },
    {
      field: 'amount',
      valueFormatter: (value) => {
        return currencyFormatter(currencyCode, value, 2);
      },
      headerName: 'Total Amount',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      valueFormatter: (value) => {
        return formatDateSlashes(value);
      },
      flex: 1,
      display: 'flex',
    },
    {
      field: 'retryAttempts',
      headerName: 'Retry Attempts',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'failureReason',
      headerName: 'Failure Reason',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'paidDate',
      headerName: 'Paid Date',
      valueFormatter: (value) => {
        return value ? formatDateSlashes(value) : '';
      },
      flex: 1,
      display: 'flex',
    },
    {
      field: 'nextChargeDate',
      headerName: 'Next Charge Date',
      valueFormatter: (value) => {
        return value ? formatDateSlashes(value) : '';
      },
      flex: 1,
      display: 'flex',
    },
  ];

  const itemizedRows = useMemo(() => {
    if (!paymentScheduleRows) {
      return [];
    }

    return paymentScheduleRows.flatMap((row) => {
      return row.items.map((item) => {
        return {
          id: `${row.id}-${item.id_items}`,
          itemId: item.id_items,
          paymentType: row.type,
          status: row.status,
          itemType: item.type,
          amount: item.type === 'service_fee' ? row.feeAmount : row.baseAmount,
        };
      });
    });
  }, [paymentScheduleRows]);

  const itemizedColumns: Array<GridColDef> = [
    {
      field: 'itemId',
      headerName: 'Item ID',
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'itemType',
      headerName: 'Item Type',
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'paymentType',
      headerName: 'Payment Type',
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.5,
      display: 'flex',
    },
  ];

  return (
    <Accordion>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Payment Schedules</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          <Grid container direction="row" gap={2}>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Payment Schedule Status
              </Typography>
              <Typography>{formatPaymentScheduleStatus(paymentScheduleDetails.result.status)}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Initial Amount
              </Typography>
              <Typography>
                {currencyFormatter(currencyCode, paymentScheduleDetails.result.initial_total_amount, 2)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Total Amount
              </Typography>
              <Typography>{currencyFormatter(currencyCode, paymentScheduleDetails.result.total_amount, 2)}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Balance Amount
              </Typography>
              <Typography>
                {currencyFormatter(currencyCode, paymentScheduleDetails.result.balance_amount, 2)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Total Paid
              </Typography>
              <Typography>{currencyFormatter(currencyCode, paymentScheduleDetails.result.total_paid, 2)}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Total Service Fee
              </Typography>
              <Typography>
                {currencyFormatter(currencyCode, paymentScheduleDetails.result.total_service_fee, 2)}
              </Typography>
            </Stack>
            {paymentScheduleDetails.result.payment_method === PaymentSchedulePaymentMethodTypes.StripeSubscription && (
              <Stack>
                <Typography variant="h6" fontWeight="bold">
                  Stripe Subscription Link
                </Typography>
                <Link href={stripeSubscriptionLink} color="inherit">
                  {paymentScheduleDetails.result.payment_method_id}
                </Link>
              </Stack>
            )}
            {paymentScheduleDetails.result.payment_method ===
              PaymentSchedulePaymentMethodTypes.StripeOffSessionCard && (
              <Stack>
                <Typography variant="h6" fontWeight="bold">
                  Payment Method ID
                </Typography>
                <Typography>{paymentScheduleDetails.result.payment_method_id}</Typography>
              </Stack>
            )}
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Schedule Template ID
              </Typography>
              <Typography>{paymentScheduleDetails.result.schedule_template_id}</Typography>
            </Stack>
          </Grid>
          <DataGridPro
            rows={paymentScheduleRows}
            autoHeight
            columns={columns}
            rowSelection={false}
            hideFooterRowCount
            disableColumnSelector
            getRowHeight={() => 'auto'}
          />
          <DataGridPro
            rows={itemizedRows}
            autoHeight
            columns={itemizedColumns}
            rowSelection={false}
            hideFooterRowCount
            disableColumnSelector
            getRowHeight={() => 'auto'}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
