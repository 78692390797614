import * as yup from 'yup';
import { currencyCodes } from '~/globals';

import {
  CUSTOM_OFFER_CANCELLATION_POLICY_TYPES,
  FLEXIBLE_CANCELLATION_POLICY,
  LE_ENTITIES,
} from '~/components/Common/CustomOffers/constants';

const today = new Date();
today.setHours(0, 0, 0, 0);

const itemSchema = {
  type: yup
    .string()
    .required('Please select an item type.')
    .oneOf(['cruise', 'tour', 'hotel', 'experience', 'other'], 'Please select a valid item type.'),
  name: yup
    .string()
    .required('Please enter the item name.')
    .max(300, 'Item name cannot be longer than 300 characters.'),
  description: yup.string().required('Please enter the item description.'),
  service_information: yup.string().required('Please enter the terms and conditions.'),
  vendor: yup.string().required('Please enter the vendor name.'),
  vendor_reference_number: yup.string().optional(),
  salesforce_case_number: yup.string().optional(),
  start_date: yup
    .date()
    .required('Please enter the start date.')
    .min(today, 'Start date cannot be in the past.')
    .test('is-before-or-equal-to-end', 'Start date must be on or before the end date.', function (value) {
      const endDate = this.parent.end_date;
      if (!value || !endDate) {
        return true;
      }
      const start = new Date(value);
      const end = new Date(endDate);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      return start.getTime() <= end.getTime();
    }),
  end_date: yup
    .date()
    .required('Please enter the end date.')
    .min(today, 'End date cannot be in the past.')
    .test('is-after-or-equal-to-start', 'End date must be on or after the start date.', function (value) {
      const startDate = this.parent.start_date;
      if (!value || !startDate) {
        return true;
      }
      const start = new Date(startDate);
      const end = new Date(value);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      return end.getTime() >= start.getTime();
    }),

  // cruise specific fields
  // required if type is cruise, otherwise optional
  cruise_line: yup.string().when('type', {
    is: 'cruise',
    then: (schema) => schema.required('Please enter the cruise line.'),
    otherwise: (schema) => schema.optional(),
  }),
  ship_name: yup.string().when('type', {
    is: 'cruise',
    then: (schema) => schema.required('Please enter the ship name.'),
    otherwise: (schema) => schema.optional(),
  }),
  ship_cabin: yup.string().when('type', {
    is: 'cruise',
    then: (schema) => schema.required('Please enter the ship cabin.'),
    otherwise: (schema) => schema.optional(),
  }),
  ship_rate: yup.string().when('type', {
    is: 'cruise',
    then: (schema) => schema.required('Please enter the ship rate.'),
    otherwise: (schema) => schema.optional(),
  }),
};

const travellerSchema = yup.object({
  name: yup.string().required('Please enter the traveller name.'),
  type: yup.string().required('Please select a traveller type.'),
  country: yup.string().required('Please select the traveller country.'),
  date_of_birth: yup.date().optional().nullable().max(new Date(), 'Date of birth must be in the past.'),
});

const paymentMetadataSchema = yup.object({
  vendor_payment_type: yup
    .string()
    .required('Please select the vendor payment type.')
    .oneOf(['deposit', 'full_payment'], 'Please select a valid vendor payment type.'),
  vendor_due_date: yup
    .date()
    .required('Please enter the vendor due date.')
    .min(new Date(), 'Vendor due date must be in the future.'),
  vendor_deposit_1_amount: yup.number().label('Deposit 1 amount').default(0).optional().min(0),
  vendor_deposit_1_due_date: yup
    .date()
    .when('vendor_payment_type', ([vendor_payment_type], schema) =>
      vendor_payment_type === 'deposit'
        ? schema.min(today, 'Vendor deposit due date must be in the future.')
        : schema.optional(),
    ),
  vendor_deposit_payment_made: yup.boolean().label('Deposit paid?').default(false).required(),
  customer_deposit_payment_made: yup.boolean().label('Deposit paid?').default(false).notRequired(),
  vendor_was_paid: yup.boolean().label('Vendor payment made?').default(false).required(),
  deposit_pi_number_customer: yup.string().label('Deposit PI number').optional(),
  deposit_vcc_digits: yup.string().label('Deposit VCC digits').optional(),
  final_payment_vcc_digits: yup.string().label('Final payment VCC digits').optional(),
  margin: yup.number().default(0),
  le_service_fee: yup.number().default(0),
  final_amount_vendor: yup
    .number()
    .required('Please enter the final amount for the vendor.')
    .min(1, 'Final amount for the vendor must be at least 1.'),
  final_amount_customer: yup
    .number()
    .required('Please enter the final amount for the customer.')
    .min(1, 'Final amount for the customer must be at least 1.'),
  payment_type: yup
    .string()
    .required('Please select the customer payment type.')
    .oneOf(['deposit', 'full_payment'], 'Please select a valid customer payment type.'),
  customer_deposit_1_due_date: yup
    .date()
    .label('Deposit 1 due date')
    .when('payment_type', ([payment_type], schema) =>
      payment_type === 'deposit'
        ? schema.min(today, 'Customer deposit due date must be in the future.')
        : schema.optional(),
    ),
  customer_deposit_1_amount: yup
    .number()
    .label('Deposit 1 amount')
    .default(0)
    .when('payment_type', ([payment_type], schema) =>
      payment_type === 'deposit' ? schema.moreThan(0, 'Customer deposit amount must be greater than 0.') : schema,
    ),
  vendor_deposit_payment_date: yup
    .date()
    .label('Vendor Deposit Payment Date')
    .when('vendor_deposit_payment_made', {
      is: true,
      then: () =>
        yup.date().required('This field is required when Vendor deposit was made').typeError('Date cannot be blank'),
      otherwise: () => yup.date().nullable(),
    }),
  vendor_payment_date: yup
    .date()
    .label('Vendor Payment Date')
    .when('vendor_was_paid', {
      is: true,
      then: () =>
        yup.date().required('This field is required when Vendor payment was made').typeError('Date cannot be blank'),
      otherwise: () => yup.date().nullable(),
    }),
  refund_received: yup.date().nullable().notRequired(),
  le_entity: yup.string().oneOf(LE_ENTITIES).required('Please select the LE Entity.'),
  commission_amount: yup.number().optional().typeError('Commission amount cannot be empty. Enter 0 if not applicable.'),
  commission_received: yup.boolean().optional().nullable(),
  additional_vendor_payment: yup
    .number()
    .optional()
    .typeError('Additional vendor payment cannot be empty. Enter 0 if not applicable.'),
  created_by: yup.string().optional(),
  sales_agent_name: yup.string().optional(),
  cancellation_type: yup.string().oneOf(CUSTOM_OFFER_CANCELLATION_POLICY_TYPES).optional().nullable(),
  cancellation_end_date: yup.date().when('cancellation_type', {
    is: FLEXIBLE_CANCELLATION_POLICY,
    then: () =>
      yup
        .date()
        .required('Please enter the cancellation end date for flexible policy.')
        .typeError('Cancellation end date cannot be blank.'),
    otherwise: () => yup.date().nullable(),
  }),
  sales_attribution: yup.string().optional().nullable(),
  // Note: unlike other fields, this should be nullable and not default to 0 if cleared or not set
  // https://github.com/jquense/yup/issues/500#issuecomment-569343680
  additional_margin: yup
    .number()
    .transform((value, original) => (original === '' || isNaN(original) ? null : value))
    .nullable(),
  reason_additional_info: yup.string().optional().nullable(),
  reason_issue_raised: yup.boolean().optional().nullable(),
});

export const customOfferSchema = yup.object({
  due_date: yup
    .date()
    .required('Please enter the customer payment due date.')
    .min(new Date(), 'Customer payment due date must be in the future.'),
  sold_at: yup.date().required('Please enter the sold date.'),
  price: yup
    .number()
    .required('Please enter the sell price.')
    .moreThan(0.99, 'Sell price must be greater than 0.99.')
    .max(9999999999, 'Sell price cannot be larger than 9,999,999,999.'),
  price_currency: yup
    .string()
    .default('AUD')
    .required('Please select the customer currency.')
    .oneOf(currencyCodes, 'Please select a valid customer currency.'),
  cost_price: yup
    .number()
    .required('Please enter the cost price.')
    .moreThan(0.99, 'Cost price must be greater than 0.99.')
    .max(9999999999, 'Cost price cannot be larger than 9,999,999,999.'),
  cost_price_currency: yup
    .string()
    .default('AUD')
    .required('Please select the vendor currency.')
    .oneOf(currencyCodes, 'Please select a valid vendor currency.'),
  payment_terms: yup.string().optional().nullable(),
  payment_metadata: paymentMetadataSchema.required(),
  item: yup.object(itemSchema).required('Item details are required.'),
  travellers: yup
    .array(travellerSchema)
    .min(1, 'Please add at least one traveller.')
    .max(10, 'Maximum of 10 travellers allowed.'),
  reason: yup.string().when('item.type', {
    is: 'other',
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required('Please select a reason for this custom offer.'),
  }),
  reasonOther: yup.string().when('reason', {
    is: 'other',
    then: () => yup.string().required('Please specify the reason for this custom offer.'),
  }),
});

export const editableCustomOfferSchema = customOfferSchema.pick(['payment_terms', 'item', 'travellers']).concat(
  yup.object({
    payment_metadata: paymentMetadataSchema.pick([
      'customer_deposit_payment_made',
      'vendor_deposit_payment_made',
      'vendor_was_paid',
    ]),
  }),
);
