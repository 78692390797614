import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';

import { InternalService, PropertyStateEnum } from '~/services/AccommodationService';

import AccommodationInternalServiceFilterInput from './AccommodationInternalServiceFilterInput';
import AccommodationPropertyStateFilterInput from './AccommodationPropertyStateFilterInput';

export interface AccommodationPropertiesSearchInput {
  propertyName?: string;
  id?: string;
  city?: string;
  country?: string;
  rating?: string;
  internalService?: InternalService | '';
  propertyState?: PropertyStateEnum | '';
}

const DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT: AccommodationPropertiesSearchInput = {
  propertyName: '',
  id: '',
  city: '',
  country: '',
  rating: '',
  internalService: '',
  propertyState: '',
};

interface Props {
  initialInput?: AccommodationPropertiesSearchInput;
  onSubmit: (formObject: Record<string, string>) => void;
  selectedTab: AccommodationTabs;
}

export default function AccommodationPropertiesSearchForm({ initialInput, onSubmit, selectedTab }: Props) {
  const [propertyName, setPropertyName] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
  const [id, setId] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  const [city, setCity] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.city);
  const [country, setCountry] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.country);
  const [rating, setRating] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.rating);
  const [propertyState, setPropertyState] = useState<PropertyStateEnum | ''>(
    DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyState,
  );
  const [internalService, setInternalService] = useState<InternalService | ''>(
    DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.internalService,
  );

  const clearSearchId = useCallback(() => {
    setId('');
  }, []);

  const clearSearches = useCallback(() => {
    setPropertyName('');
    setCity('');
    setCountry('');
    setRating('');
    setInternalService('');
    setPropertyState('');
  }, []);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      const formObject: Record<string, string> = {};

      if (propertyName) {
        formObject.propertyName = propertyName;
      }
      if (id) {
        formObject.id = id;
      }
      if (city) {
        formObject.city = city;
      }
      if (country) {
        formObject.country = country;
      }
      if (rating) {
        formObject.rating = rating;
      }
      if (internalService) {
        formObject.internalService = internalService;
      }
      if (propertyState) {
        formObject.propertyState = propertyState;
      }
      onSubmit(formObject);
    },
    [onSubmit, propertyName, id, city, country, rating, internalService, propertyState],
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      setPropertyName(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
      setId(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
      setCity(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.city);
      setCountry(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.country);
      setRating(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.rating);
      setInternalService(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.internalService);
      setPropertyState(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyState);
      onSubmit({});
    },
    [onSubmit],
  );

  useEffect(() => {
    setPropertyName(initialInput?.propertyName ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
    setId(initialInput?.id ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
    setCity(initialInput?.city ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.city);
    setCountry(initialInput?.country ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.country);
    setRating(initialInput?.rating ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.rating);
    setInternalService(initialInput?.internalService ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.internalService);
    setPropertyState(initialInput?.propertyState ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyState);
  }, [initialInput]);

  const getIdLabel = useCallback(() => {
    if ([AccommodationTabs.UNMAPPED_SUPPLIERS, AccommodationTabs.POTENTIAL_MAPPINGS].includes(selectedTab)) {
      return 'Supplier ID / Internal Service ID';
    }
    return 'Property ID / Supplier ID / Internal Service ID / Offer ID';
  }, [selectedTab]);

  return (
    <form onSubmit={handleSubmission} onReset={handleReset}>
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Search by ID
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                label={getIdLabel()}
                value={id}
                type="text"
                onChange={(e) => setId(e.target.value)}
                onFocus={clearSearches}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" type="submit">
                Search
              </Button>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Filter properties
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Property name"
                value={propertyName}
                type="text"
                onChange={(e) => setPropertyName(e.target.value)}
                onFocus={clearSearchId}
                fullWidth
              />
            </Grid>
            {[AccommodationTabs.UNMAPPED_SUPPLIERS, AccommodationTabs.POTENTIAL_MAPPINGS].includes(selectedTab) && (
              <Grid item xs={12} sm={3}>
                <AccommodationInternalServiceFilterInput value={internalService} onChange={setInternalService} />
              </Grid>
            )}
            {selectedTab === AccommodationTabs.PROPERTIES && (
              <>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="City"
                    value={city}
                    type="text"
                    onChange={(e) => setCity(e.target.value)}
                    onFocus={clearSearchId}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Country"
                    value={country}
                    type="text"
                    onChange={(e) => setCountry(e.target.value)}
                    onFocus={clearSearchId}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="star-rating-label">Star rating</InputLabel>
                    <Select
                      labelId="star-rating-label"
                      label="Star rating"
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      onFocus={clearSearchId}
                    >
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={3.5}>3.5</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={4.5}>4.5</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <AccommodationPropertyStateFilterInput value={propertyState} onChange={setPropertyState} />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button variant="text" type="reset">
            Reset
          </Button>
          <Button variant="contained" type="submit">
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}
